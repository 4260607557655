import axios from 'axios';

const getToken = () => {
    return localStorage.getItem('userToke');
};
const getUserType = () => {
    return localStorage.getItem('userType');
};

const logoutUser = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('userToke');
    localStorage.removeItem('userType');

    const userType = getUserType(); // Fetch userType before logout redirection
    if (userType === 'Merchant') {
        window.location.href = '/login';
    } else {
        window.location.href = '/admin';
    }
};

const api = axios.create({
    baseURL: 'https://b2bconnect.gaintlogistic.com/v1/',
    timeout: 50000,
    headers: {
        "Content-Type": 'application/json',
        "Accept": 'application/json'
    },
});

api.interceptors.request.use(config => {
    const token = getToken(); // Fetch the latest token for each request
    const userType = getUserType(); // Fetch the latest userType for each request

    if (token) {
        config.headers['token'] = token;
    }
    config.headers['user-type'] = userType;

    return config;
}, error => {
    return Promise.reject(error);
});

api.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            logoutUser();
        }
        return Promise.reject(error);
    }
);

const commonApi = {
    get: (url, params = {}) => {
        return api.get(url, { params });
    },
    post: (url, data = {}) => {
        const headers = {};
        if (data instanceof FormData) {
            headers['Content-Type'] = 'multipart/form-data';
        }
        return api.post(url, data, { headers });
    },
};

export default commonApi;
