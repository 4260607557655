import React, { createContext, useState, useEffect, useContext } from 'react';
import commonApi from '../utils/api';
import { CommonContext } from './CommonContext';
import moment from 'moment/moment';
import { useNavigate } from 'react-router-dom';

export const WeightContext = createContext();

export function WeightContextProvider({ children }) {
    const { user } = useContext(CommonContext);
    const navigate = useNavigate();
    const [addModal, setAddModal] = useState(false);
    const [allData, setAllData] = useState([]);
    const [allWeight, setAllWeight] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [firstLoading, setFirstLoading] = useState(false);
    const [viewData, setViewData] = useState('');
    const [isEdit, setIsEdit] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMesaage, setAlertMesaage] = useState('');
    const [severity, setSeverity] = useState('');
    const [alertModal, setAlertModal] = useState(false);
    const [uploadDataRes, setUploadDataRes] = useState();

    const defaultStartDate = moment().startOf('month');
    const defaultEndDate = moment().endOf('month');
    const defaultDateRange = [defaultStartDate.toDate(), defaultEndDate.toDate()];
    const [fStartDate, setFStartDate] = useState(defaultStartDate);
    const [fEndDate, setFEndDate] = useState(defaultEndDate);
    const [fDateRange, setFDateRange] = useState(defaultDateRange);
    const [filterInput, setfilterInput] = useState({});
    const [filterType, setFilterType] = useState('LR No.');
    const [searchValue, setSearchValue] = useState('');
    const [selectedCourierIds, setSelectedCourierIds] = useState([]);
    const [selectedMerchantIds, setSelectedMerchantIds] = useState([]);

    const handleAlertModalModal = () => {
        setAlertModal(true);
    };
    const handleCloseAlertModalModal = () => {
        setAlertModal(false);
        onLoadApi();
    };
    const onCloseAlert = () => {
        setOpenAlert(false);
        setAlertMesaage('');
        setSeverity('')
    };

    const handleAddModal = () => {
        setAddModal(true);
    };

    const handleCloseModal = () => {
        setViewData('');
        setIsEdit(false);
        setAddModal(false);
    };

    useEffect(() => {
        const fInput = {
            searchBy: searchValue ? filterType.toLowerCase().replace(/\s/g, '_').replace(/\./g, '') ?? '' : '',
            searchValue: searchValue ?? '', courier_id: selectedCourierIds ?? [],
            merchant_id: user?.user_type !== 'Merchant' ? selectedMerchantIds : [user?.id],
            start_date: fDateRange ? moment(fDateRange[0]).format('YYYY-MM-DD') : fStartDate ?? '',
            end_date: fDateRange ? moment(fDateRange[1]).format('YYYY-MM-DD') : fEndDate ?? '',
        }

        setfilterInput(fInput)
        // eslint-disable-next-line
    }, [searchValue, filterType, fDateRange, selectedCourierIds, selectedMerchantIds]);

    const onLoadApi = () => {
        // let postReq = {};
        let postReq = {
            searchBy: searchValue ? filterType.toLowerCase().replace(/\s/g, '_').replace(/\./g, '') ?? '' : '',
            searchValue: searchValue ?? '', courier_id: selectedCourierIds ?? [],
            merchant_id: user?.user_type !== 'Merchant' ? selectedMerchantIds : [user?.id],
            start_date: fDateRange ? moment(fDateRange[0]).format('YYYY-MM-DD') : fStartDate ?? '',
            end_date: fDateRange ? moment(fDateRange[1]).format('YYYY-MM-DD') : fEndDate ?? '',
            // order_status_id: osId !== 99 ? osId : ''
        };
        if (user?.user_type === "Merchant") {
            postReq.merchant_id = user?.id
        } else {
            postReq.admin_id = user?.id
        }
        setfilterInput(postReq);
        // if (user?.user_type === "Merchant") {
        //     postReq = { merchant_id: user?.id }
        // } else {
        //     postReq = { admin_id: user?.id }
        // }

        setFirstLoading(false);
        commonApi.post('/weight-mismatch/list', postReq)
            .then((response) => {
                setFirstLoading(true);
                if (response?.data?.data) {
                    setAllWeight(response?.data?.data);
                    setAllData(response?.data?.data);
                }
            })
            .catch((error) => {
                setFirstLoading(true);
                console.error('Error fetching data:', error);
            });
    };
    const onExportWeight = () => {
        if (user?.user_type !== "Merchant") {
            filterInput.admin_id = user?.id
        } else {
            filterInput.merchant_id = user?.id
        }
        setIsLoading(true);
        commonApi.post('/weight-mismatch/export', filterInput)
            .then((response) => {
                setIsLoading(false);
                setOpenAlert(true);
                if (response?.data?.s === 0) {
                    setAlertMesaage(response?.data?.msg);
                    setSeverity('error')
                } else {
                    navigate('/download');
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.error('Error fetching data:', error);
                setOpenAlert(true);
                setAlertMesaage(error?.response?.data?.msg);
                setSeverity('error')
            });
    };
    useEffect(() => {
        onLoadApi()
        // eslint-disable-next-line
    }, [])

    // You can provide any values or functions you want to make available to consumers here
    const contextValue = {
        onExportWeight,
        filterType,
        searchValue,
        selectedCourierIds,
        selectedMerchantIds,
        setSelectedMerchantIds,
        setSelectedCourierIds,
        setSearchValue,
        setFilterType,
        fDateRange,
        filterInput,
        fStartDate,
        fEndDate,
        uploadDataRes,
        alertModal,
        handleCloseAlertModalModal,
        handleAlertModalModal,
        onCloseAlert,
        severity,
        alertMesaage,
        openAlert,
        isEdit,
        onLoadApi,
        viewData,
        isLoading,
        firstLoading,
        allWeight,
        allData,
        handleAddModal,
        handleCloseModal,
        setAddModal,
        addModal,
        setAllData,
        setAllWeight,
        setFirstLoading,
        setIsLoading,
        setViewData,
        setIsEdit,
        setSeverity,
        setAlertMesaage,
        setOpenAlert,
        setAlertModal,
        setUploadDataRes,
        setFEndDate,
        setFStartDate,
        setfilterInput,
        setFDateRange,
    };

    // Render the provider with children
    return <WeightContext.Provider value={contextValue}>{children}</WeightContext.Provider>;
}
